import React from "react"
import Layout from '../../layouts/layout.js'
import { Seo } from '../../components/Seo/Seo'
import Pagetitle from '../../components/Pagetitle'

const Index = () => (

    <Layout>
      <div>
        <Seo title="Articles | Wayward Media | Sowerby Bridge" />
        <Pagetitle title="Articles"/>
       <p>Design, Photography and Life Related</p>
      </div>
    </Layout>
)

export default Index